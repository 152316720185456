import React from "react";
import styled from "@emotion/styled";
import { SnippetLanguage } from "../../data/interfaces";
import { SquareCard, StyledSquareCardContainer } from "../primitives/Cards";

const LanguageCards = function({ languages }: { languages: SnippetLanguage[] }) {
  return (
    <StyledLanguageCards>
      {languages.map((language) => (
        <LanguageCard key={language.key} language={language} />
      ))}
    </StyledLanguageCards>
  );
};

export default LanguageCards;

const StyledLanguageCards = styled(StyledSquareCardContainer)`
  .square-card .thumbnail img {
    border-radius: 0;
    /* width: 60px; */
    opacity: 0.75;
  }
  .square-card .description .number {
    font-size: 1.15em;
    font-weight: bold;
  }
`;
export function LanguageCard({ language }: { language: SnippetLanguage }) {
  return (
    <SquareCard
      title={language.name}
      thumbnail={"/assets/icons/" + language.key + ".svg"}
      url={"/explore/languages/" + language.key}
      description={
        <div>
          <span className="number">{language.snippetCount}</span> snippets
        </div>
      }
    />
  );
}
