import React from "react";
import { SelectOption } from "../../data/interfaces";
import { PickerMulti, PickerSingle } from "./Picker";

function CollectionPicker({
  value,
  onChange,
  required = false,
  creatable = false,
  isMulti = false,
  options = [],
}: CollectionPickerProps) {
  let Picker = isMulti ? PickerMulti : PickerSingle;
  return (
    <Picker
      options={options}
      label="Collection"
      name="collection"
      onChange={onChange}
      value={value as any}
      required={required}
      creatable={creatable}
    />
  );
}

export default React.memo(CollectionPicker);

export interface CollectionPickerProps {
  value: string | string[];
  onChange: (collection: string | string[]) => void;
  required?: boolean;
  creatable?: boolean;
  isMulti?: boolean;
  options: SelectOption[];
}
