import React from "react";
import styled from "@emotion/styled";
import { CodeSnippet } from "../../data/interfaces";
import SnippetInfoProperty from "./SnippetInfoProperty";
import { format } from "date-fns";
import { RightPanel } from "../primitives/Container";
import Tags, { StyledTag } from "../snippets/Tags";
import { Link } from "@reach/router";

export default function SnippetInfo({ snippet }: { snippet: CodeSnippet }) {
  if (!snippet) return null;
  return (
    <RightPanel>
      <SnippetInfoProperty label="Title">{snippet.title}</SnippetInfoProperty>
      <SnippetInfoProperty label="Language">
        <Link className="language monospace" to={"/explore/languages/" + snippet.language.key}>
          {snippet.language.name}
        </Link>
      </SnippetInfoProperty>
      <SnippetInfoProperty label="Tags">
        <Tags tags={snippet.tags} />
      </SnippetInfoProperty>
      <SnippetInfoProperty label="Description">{snippet.description}</SnippetInfoProperty>

      <SnippetInfoProperty label="Author">{snippet.createdBy.name}</SnippetInfoProperty>
      <SnippetInfoProperty label="Updated">
        {format(snippet.modified, "MM/DD/YYYY")}
      </SnippetInfoProperty>
      <SnippetInfoProperty label="Path">
        <pre>{snippet.path}</pre>
      </SnippetInfoProperty>
    </RightPanel>
  );
}
