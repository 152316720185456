/* @jsx mdx */
import React from 'react';
import { mdx } from '@mdx-js/react'
/* @jsx mdx */


const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`CodeSnippets.dev CLI`}</h1>
    <p>{`Imagine you created a nice little utility function, `}<inlineCode parentName="p">{`getQueryStringParam.js`}</inlineCode>{` inside of `}<strong parentName="p">{`ProjectA`}</strong>{`. You can do "future you" a favor and `}<inlineCode parentName="p">{`export`}</inlineCode>{` it as code snippet.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-text"
      }}>{`snippets export src/utils/getQueryStringParam.js
`}</code></pre>
    <p>{`Then two months later, when the same need arises while working on `}<strong parentName="p">{`ProjectB`}</strong>{`, you can quickly `}<inlineCode parentName="p">{`import`}</inlineCode>{` it.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-text"
      }}>{`snippets import getQueryStringParam.js --out src/shared
`}</code></pre>
    <h2>{`Install / Setup`}</h2>
    <p>{`Install the CLI via NPM`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`npm install -g codesnippets-cli
`}</code></pre>
    <p>{`Then login:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`snippets login
`}</code></pre>
    <h2>{`Available Commands`}</h2>
    <ul>
      <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
            "href": "#list"
          }}>{`list`}</a></strong>{` - Display a list of your (or a specified user's) code snippets`}</li>
      <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
            "href": "#export"
          }}>{`export <filepath`}{`>`}</a></strong>{` - Export a local file to create a new CodeSnippet`}</li>
      <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
            "href": "#import"
          }}>{`import <snippetPath`}{`>`}</a></strong>{` - Import a remote CodeSnippet to be saved as a local file.`}</li>
      <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
            "href": "#remove"
          }}>{`remove <snippetPath`}{`>`}</a></strong>{` - Remove a CodeSnippet you have created`}</li>
      <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
            "href": "#settings"
          }}>{`settings`}</a></strong>{` - Display your CodeSnippets.dev CLI settings`}</li>
      <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
            "href": "#login--logout"
          }}>{`login`}</a></strong>{` - Log into the CodeSnippets.dev CLI`}</li>
      <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
            "href": "#login--logout"
          }}>{`logout`}</a></strong>{` - Log out of the CodeSnippets.dev CLI`}</li>
    </ul>
    <h2>{`List`}</h2>
    <p>{`Display the available code snippets. By default your own, but you can specify a CodeSnippets.dev user.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`snippets list [--user <value>] [--collection <value>]
`}</code></pre>
    <h3>{`Show all of your own snippets`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`snippets list
`}</code></pre>
    <h3>{`Show all your snippets from a specified collection`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`snippets list --collection utils
`}</code></pre>
    <h3>{`Show all of another user's snippets`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`snippets list --user droopytersen
`}</code></pre>
    <h2>{`Export`}</h2>
    <p>{`Export a local file to be available as a CodeSnippets.dev snippet.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`snippets export <filepath> [--title <value>] [--collection <value>] [--details <value>] [--overwrite]
`}</code></pre>
    <ul>
      <li parentName="ul">{`If already have a snippet with a matching `}<inlineCode parentName="li">{`filename`}</inlineCode>{` and `}<inlineCode parentName="li">{`collection`}</inlineCode>{`, you must pass the `}<inlineCode parentName="li">{`--overwrite`}</inlineCode>{` flag.`}</li>
      <li parentName="ul">{`When you run the export command without any options you will be prompted for the required parmeters.`}</li>
    </ul>
    <h3>{`Export a file`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`snippets export src/components/Slider.jsx
`}</code></pre>
    <h3>{`Export a file to a specific collection`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`snippets export src/components/Slider.jsx --collection components
`}</code></pre>
    <h2>{`Import`}</h2>
    <p>{`Save available CodeSnippets.dev snippets as local files.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`snippets import <snippet> [--path <value>] [--overwrite]
`}</code></pre>
    <ul>
      <li parentName="ul">{`If a local file already exists, you will need to pass the `}<inlineCode parentName="li">{`--overwrite`}</inlineCode>{` flag`}</li>
      <li parentName="ul">{`If the given `}<inlineCode parentName="li">{`--path`}</inlineCode>{` doesn't exist, you will receive an error`}</li>
    </ul>
    <h3>{`Import a snippet file to the current folder`}</h3>
    <p><em parentName="p">{`Get your snippet that has a filename of `}<inlineCode parentName="em">{`Slider.tsx`}</inlineCode>{` and save it locally to the current directory`}</em></p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`snippets import Slider.tsx
`}</code></pre>
    <h3>{`Import a snippet file to a specific folder`}</h3>
    <blockquote>
      <p parentName="blockquote">{`If the destination folder doesn't exist, it will automatically be created`}</p>
    </blockquote>
    <p><em parentName="p">{`Get your snippet that has a filename of `}<inlineCode parentName="em">{`Slider.tsx`}</inlineCode>{` and save it locally to `}<inlineCode parentName="em">{`src/components/Slider`}</inlineCode></em></p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`snippets import Slider.tsx --out src/components/Slider
`}</code></pre>
    <h3>{`Import a snippet to a specific folder with a different filename`}</h3>
    <p>{`If you include a filename in the `}<inlineCode parentName="p">{`--out`}</inlineCode>{` path, then that filename will be used instead of the Snippet's filename.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`snippets import Slider.tsx --out src/components/AnimatedSlider/AnimatedSlider.tsx
`}</code></pre>
    <h3>{`Import a snippet file from a specific collection`}</h3>
    <p><em parentName="p">{`Look in your `}<inlineCode parentName="em">{`utils`}</inlineCode>{` collection for a snippet named `}<inlineCode parentName="em">{`debounce.js`}</inlineCode>{` and save it to the current folder`}</em></p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`snippets import utils/debounce.js
`}</code></pre>
    <h3>{`Import an entire collection`}</h3>
    <p>{`You can import an entire collection with the `}<inlineCode parentName="p">{`COLLECTION/`}</inlineCode>{` syntax`}</p>
    <p><em parentName="p">{`Import every snippet file that exists in your `}<inlineCode parentName="em">{`utils`}</inlineCode>{` collection and save them locally in the `}<inlineCode parentName="em">{`src/utils`}</inlineCode>{` folder.`}</em></p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`snippets import utils/ --out src/utils
`}</code></pre>
    <h3>{`Import another user's entire collection`}</h3>
    <p>{`You can import an entire collection of another user with the `}<inlineCode parentName="p">{`USERNAME/COLLECTION/`}</inlineCode>{` syntax`}</p>
    <p><em parentName="p">{`Import every snippet file that exists in DroopyTersen's `}<inlineCode parentName="em">{`utils`}</inlineCode>{` collection and save them locally in the `}<inlineCode parentName="em">{`src/utils`}</inlineCode>{` folder.`}</em></p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`snippets import droopytersen/utils/ --out src/utils
`}</code></pre>
    <h2>{`Login / Logout`}</h2>
    <p>{`Log in and out of the CodeSnippets.dev CLI`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`snippets login
snippets logout
`}</code></pre>
    <h2>{`Settings`}</h2>
    <p>{`For development only. Allows you to see your env configuration as well as switch from `}<inlineCode parentName="p">{`local`}</inlineCode>{` to `}<inlineCode parentName="p">{`dev`}</inlineCode>{` to `}<inlineCode parentName="p">{`prod`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`snippets settings [--env <value>]
`}</code></pre>
    <h3>{`Display current environment settings`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`snippets settings
`}</code></pre>
    <h3>{`Switch to a different environment`}</h3>
    <p><em parentName="p">{`Switch to the `}<inlineCode parentName="em">{`dev`}</inlineCode>{` environment. You might have to login again if it is your first time using the targeted env.`}</em></p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`snippets settings --env dev
`}</code></pre>
    </MDXLayout>;
}

MDXContent.isMDXComponent = true;
