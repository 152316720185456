import React from "react";
import { CodeSnippet } from "../../data/interfaces";
import SnippetCard from "./SnippetCard";
import styled from "@emotion/styled";
import { CardContainer } from "../primitives/Cards";

function SnippetCards({ snippets }: SnippetCardsProps) {
  return (
    <StyledSnippetCards className="snippet-cards">
      {snippets.map((snippet) => (
        <SnippetCard key={snippet.key} snippet={snippet} />
      ))}
    </StyledSnippetCards>
  );
}
export default React.memo(SnippetCards);

export interface SnippetCardsProps {
  // Props
  snippets: CodeSnippet[];
}

const StyledSnippetCards = styled(CardContainer)`
  position: relative;
`;
