import React from "react";
import Layout, { BaseScreenProps, StyledResults } from "./Layout";
import {
  TextSearch,
  LanguageRefiner,
  AuthorRefiner,
  CollectionRefiner,
  TagsRefiner,
  CreatedDateRefiner,
  getRefinerPropOverrides,
} from "../components/search/Refiners";
import { StyledBar } from "../components/pageHeader/CommandBar";
import { SelectedFilters } from "@appbaseio/reactivesearch";
import ResultStats from "../components/search/ResultStats";
import Breadcrumb from "../components/pageHeader/Breadcrumb";
import { StyledFilterPanel } from "../components/snippets/FilterPanel";
import SearchResults from "../components/search/SearchResults";
import styled from "@emotion/styled";

export default function SearchScreen({ language, tag, username, collection }: SearchScreenProps) {
  return (
    <Layout hideCommandBar={true}>
      <StyledSearchLayout>
        <StyledBar className="command-bar">
          <Breadcrumb />
          <SelectedFilters showClearAll={false} />
          <ResultStats />
        </StyledBar>
        <StyledFilterPanel className="filter-panel">
          <TextSearch />
          <LanguageRefiner {...getRefinerPropOverrides(language)} />
          <AuthorRefiner {...getRefinerPropOverrides(username)} />
          <CollectionRefiner {...getRefinerPropOverrides(collection, false)} />
          <TagsRefiner {...getRefinerPropOverrides(tag)} />
          <CreatedDateRefiner />
        </StyledFilterPanel>
        <SearchResults />
      </StyledSearchLayout>
    </Layout>
  );
}

export interface SearchScreenProps extends BaseScreenProps {
  language?: string;
  tag?: string;
  username?: string;
  collection?: string;
}

export const StyledSearchLayout = styled.div`
  display: grid;
  grid-template-columns: 300px 1fr;
  grid-template-rows: 50px 1fr;
  grid-template-areas: "command-bar command-bar" "filter-panel results";
  height: 100%;

  .command-bar {
    grid-area: command-bar;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .filter-panel {
    grid-area: filter-panel;
    display: flex;
    flex-direction: column;

    & > * {
      margin-bottom: 30px;
    }
  }

  .results {
    grid-area: results;
    position: relative;
    background: var(--black-transparent);
    border-top: 1px solid #555;
    padding: 20px 20px;
  }
`;
