import React from "react";
import { CodeSnippet } from "../../data/interfaces";
import styled from "@emotion/styled";
import { StyledTabHeader } from "../primitives/Container";
import SnippetCards from "./SnippetCards";

export default function SnippetGroup({ title, snippets }) {
  return (
    <StyledSnippetSection className="snippet-group">
      <StyledTabHeader>{title}</StyledTabHeader>
      <SnippetCards snippets={snippets} />
    </StyledSnippetSection>
  );
}

export interface SnippetSectionProps {
  title: string | JSX.Element;
  snippets: CodeSnippet[];
}

const StyledSnippetSection = styled.div`
  margin-bottom: 40px;
`;
