import { getServerlessUrl, getEnv } from "../utils/config";
import * as qs from "querystring";

const CACHE_KEY = "code-snippets-auth-v2";
localStorage.removeItem("code-snippets-auth");
export var ensureLogin = async function() {
  var qsToken = getTokenFromUrl();

  if (qsToken) {
    // TODO: Handle error properly
    if (qsToken === "undefined") return alert("Error authenticating to GITHUB");
    cacheToken(qsToken);
    window.history.pushState(null, "clear access token from url", location.pathname);
    return qsToken;
  }
  var cachedToken = getTokenFromCache();
  if (cachedToken) {
    return cachedToken;
  }
  return null;
};

export const redirectToLogin = function() {
  let serverlessEnv = getEnv();
  if (serverlessEnv === "local") serverlessEnv = "dev";
  let url = `${getServerlessUrl(serverlessEnv)}/login?redirect_env=${getEnv()}&redirect_path=${
    window.location.pathname
  }`;
  window.location.href = url;
};

var getTokenFromUrl = function() {
  let { access_token } = qs.parse(window.location.search.substr(1));
  return (access_token as string) || null;
};
export const checkIsLoggedIn = function() {
  return !!getTokenFromCache();
};
export const getTokenFromCache = function(): string {
  try {
    var { access_token } = JSON.parse(localStorage.getItem(CACHE_KEY));
    return access_token;
  } catch (e) {
    return null;
  }
};

var cacheToken = function(access_token: string) {
  localStorage.setItem(CACHE_KEY, JSON.stringify({ access_token }));
};

export const getAccessToken = getTokenFromCache;
