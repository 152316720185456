import FormControl from "./FormControl";
import { lighten, darken } from "polished";
import styled from "@emotion/styled";

const StyledSelect = styled(FormControl)`
  .react-select__control {
    font-size: 0.8rem;
    background: var(--black);
    color: var(--white);
    border: 1px solid var(--light);
    border-radius: 3px;
    box-shadow: none;
    font-family: Montserrat, sans-serif;
    .react-select__input {
      color: var(--light);
      font-weight: 200;
    }
    &.react-select__control--menu-is-open,
    &:hover {
      border-color: var(--primary);
    }
    .react-select__dropdown-indicator {
      color: var(--light);
      padding: 5px;
    }

    &:hover {
      .react-select__dropdown-indicator {
        color: var(--white);
      }
    }
    .react-select__single-value {
      color: var(--white);
    }
    .react-select__multi-value {
      color: var(--white);
      font-size: 0.9rem;
      background: var(--dark);
      padding: 3px;
      .react-select__multi-value__label {
        color: var(--white);
      }
    }
  }
  .react-select__menu {
    z-index: 2;
    font-size: 0.8rem;
    font-family: Montserrat, sans-serif;
    border: 1px solid var(--primary-ligher);
    background: var(--black);
    color: var(--white);
    .react-select__option--is-focused {
      background: var(--dark);
      /* background: ${(props) => lighten(0.2, props.theme.black)}; */
    }
    .react-select__option--is-selected {
      background: var(--primary);
    }
  }
`;
export default StyledSelect;
