import React, { useEffect } from "react";
import LogRocket from "logrocket";

let _initialized = false;

function _useLogRocket(
  project: string,
  user: { key: string; [key: string]: any },
  testSkip: () => boolean = () => false
) {
  useEffect(() => {
    if (_initialized || testSkip()) return;
    LogRocket.init(project);
    _initialized = true;
  }, [project, JSON.stringify(user)]);

  useEffect(() => {
    if (_initialized && user) {
      LogRocket.identify(user.key, user);
    }
  }, [JSON.stringify(user)]);
}

export default function useLogRocket(user) {
  _useLogRocket("rpphcv/code-snippets", user, () => {
    // skip for yourself (only 1000 sessions per month)
    if (!user) return true;
    if (user && user.key === "droopytersen") return true;
    return location.host.toLowerCase() === "codesnippets.dev";
  });
}
