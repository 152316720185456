import React from "react";
import { SnippetFilter, Refiners } from "../../data/interfaces";
import styled from "@emotion/styled";
import { LeftPanel } from "../primitives/Container";
import Textbox from "../inputs/Textbox";
import CollectionPicker from "../inputs/CollectionPicker";
import LanguagePicker from "../inputs/LanguagePicker";
import UserPicker from "../inputs/UserPicker";
import TagPicker from "../inputs/TagPicker";

function FilterPanel({ filter, updateFilter, excludes = [], refiners = {} }: FilterPanelProps) {
  return (
    <StyledFilterPanel>
      <h3>Filter</h3>
      <Textbox
        value={filter.text || ""}
        onChange={updateFilter}
        label="Title/Description"
        id="text"
        debounce={150}
        autofocus={true}
      />
      {!excludes.includes("tags") && (
        <TagPicker
          onChange={(value) => updateFilter("tags", value)}
          values={filter.tags}
          options={refiners.tags}
          creatable={false}
        />
      )}
      {!excludes.includes("user") && (
        <UserPicker
          onChange={(value) => updateFilter("user", value)}
          value={filter.user}
          options={refiners.users}
        />
      )}
      {!excludes.includes("languages") && (
        <LanguagePicker
          onChange={(value: string[]) => updateFilter("languages", value)}
          value={filter.languages || []}
          isMulti={true}
          options={refiners.languages}
        />
      )}
      {!excludes.includes("collections") && (
        <CollectionPicker
          onChange={(value) => updateFilter("collections", value)}
          value={filter.collections}
          isMulti={true}
          options={refiners.collections}
        />
      )}
    </StyledFilterPanel>
  );
}
export default React.memo(FilterPanel);

export interface FilterPanelProps {
  filter: SnippetFilter;
  updateFilter: (key: string, value: any) => void;
  excludes?: string[];
  refiners: Refiners;
}

export const StyledFilterPanel = styled(LeftPanel)`
  margin: 0;
  padding: 20px 10px 0 10px;
  display: flex;
  background: var(--blackgreen);
  border-color: rgba(255, 255, 255, 0.2);
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  /* border-bottom: 1px solid rgba(255, 255, 255, 0.2); */
  flex-direction: column;
  > h3 {
    margin-bottom: 10px;
  }
`;
