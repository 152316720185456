import React, { useState, useEffect, useMemo } from "react";
import gql from "graphql-tag";
import useApolloQueryWithCache from "./apollo/useApolloQueryWithCache";

export default function useLanguages() {
  let data = useApolloQueryWithCache({ query: LANGUAGES_QUERY }, "languages", 3000) || [];
  let languages = useMemo(() => (data && data.languages ? data.languages : []), [data]);
  return { languages };
}

const LANGUAGES_QUERY = gql`
  query GetLanguages {
    languages {
      key
      name
      extensions
    }
  }
`;
