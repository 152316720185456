import React from "react";
import Layout, { BaseScreenProps } from "./Layout";
import gql from "graphql-tag";
import useApolloQueryWithCache from "../hooks/apollo/useApolloQueryWithCache";
import { CodeSnippet, SnippetUser, SnippetTag, SnippetLanguage } from "../data/interfaces";
import styled from "@emotion/styled";
import Tags from "../components/snippets/Tags";
import { StyledTabHeader } from "../components/primitives/Container";
import SnippetGroup from "../components/snippets/SnippetGroup";
import UserCards from "../components/snippets/UserCards";
import LanguageCards from "../components/snippets/LanguageCards";

function ExploreScreen({ location }: BaseScreenProps) {
  let { tags, languages, users, recentSnippets } = useExploreData();
  return (
    <Layout hideCommandBar={true}>
      <StyledLayout>
        <div className="topleft">
          <div className="users">
            <StyledTabHeader>Users</StyledTabHeader>
            <UserCards users={users} />
          </div>
        </div>
        <div className="topright">
          <div className="tags-section">
            <StyledTabHeader>Tags</StyledTabHeader>
            <Tags tags={tags.slice(0, 20)} />
          </div>
          <div>
            <StyledTabHeader>Languages</StyledTabHeader>
            <LanguageCards languages={languages} />
          </div>
        </div>
        <div className="recent-snippets">
          <SnippetGroup title="Recent Snippets" snippets={recentSnippets} />
        </div>
      </StyledLayout>
    </Layout>
  );
}

export default ExploreScreen;

const StyledLayout = styled.div`
  height: 100%;
  box-sizing: border-box;
  padding: 25px;
  display: grid;
  grid-template-columns: 0.9fr 1.7fr;
  grid-template-rows: auto 1fr;
  grid-template-areas: "topleft topright" "main main";
  grid-gap: 40px;
  background: var(--black-transparent);
  .topleft {
    grid-area: topleft;
    display: flex;
    flex-direction: column;
  }
  .topright {
    grid-area: topright;
  }
  .recent-snippets {
    grid-area: main;
  }
  .tags-section {
    margin-bottom: 40px;
    font-size: 1.25em;
    .tags > * {
      margin: 0 10px 10px 0;
    }
  }
`;

let useExploreData = function() {
  let data = useApolloQueryWithCache(
    {
      query: EXPLORE_QUERY,
    },
    "explore-data",
    100
  );

  let recentSnippets: CodeSnippet[] = [];
  let users: SnippetUser[] = [];
  let tags: SnippetTag[] = [];
  let languages: SnippetLanguage[] = [];
  if (data && data.explore) {
    recentSnippets = data.explore.recentSnippets;
    users = data.explore.users;
    tags = data.explore.tags;
    languages = data.explore.languages;
  }
  return {
    users,
    recentSnippets,
    tags,
    languages,
  };
};
const EXPLORE_QUERY = gql`
  query GetExploreData {
    explore {
      recentSnippets {
        title
        key
        filename
        path
        language {
          name
          key
        }
        description
        tags {
          key
        }
        created
        createdBy {
          key
          username
          profilePhoto
        }
      }
      tags {
        key
        snippetCount
      }
      languages {
        name
        key
        snippetCount
      }
      users {
        key
        username
        name
        profilePhoto
        snippetCount
      }
    }
  }
`;
