import {
  SaveSnippetInput,
  CodeSnippet,
  SnippetLanguage,
  SnippetCollection,
  SnippetFilter,
  SnippetSearchResult,
  SnippetUser,
  SnippetTag,
} from "./interfaces";
import { uniqBy, camelCase, upperFirst, intersection, orderBy, kebabCase } from "lodash";

export const DEFAULT_SNIPPET = {
  title: "New Snippet",
  tags: [],
  language: { key: "javascript", name: "JavaScript" },
  filename: "",
  code: "// YOUR CODE HERE!",
  collection: "misc",
};

export const snippetSearchResultToCodeSnippet = function(
  result: SnippetSearchResult,
  languages: SnippetLanguage[] = []
): CodeSnippet {
  let language = languages.find((l) => l.key === result.language) || {
    key: result.language,
    name: result.language,
  };
  let createdBy: SnippetUser = {
    key: result.createdBy,
    username: result.createdBy,
  };
  let tags: SnippetTag[] = result.tags.map((t) => ({ key: t }));
  return {
    ...result,
    language,
    createdBy,
    tags,
    path: [result.createdBy, result.collection, result.filename].join("/"),
  };
};
export const snippetToSnippetInput = function(snippet: CodeSnippet): SaveSnippetInput {
  if (!snippet) return null;

  let snippetInput: SaveSnippetInput = {
    title: snippet.title || "New Snippet " + new Date().toLocaleString(),
    tags: (snippet.tags || []).map((t) => t.key),
    language: snippet.language ? snippet.language.key : "text",
    filename: snippet.filename,
    code: snippet.code,
    key: snippet.key,
    collection: snippet.collection,
    description: snippet.description,
  };
  return snippetInput;
};

export const snippetTitleToFilename = function(title: string, language: SnippetLanguage) {
  if (!title) return "";
  let name = upperFirst(camelCase(title));
  let ext =
    language && language.extensions && language.extensions.length
      ? "." + language.extensions[0]
      : "";
  return name + ext;
};

const filterByTags = function(tags: string[], snippets: CodeSnippet[]) {
  return snippets.filter((snippet: CodeSnippet) => {
    if (!tags || !tags.length) return snippets;

    let matches = intersection(tags, snippet.tags.map((t) => t.key));
    return !!matches.length;
  });
};

const filterByLanguages = function(languages: string[], snippets: CodeSnippet[]) {
  if (!languages || !languages.length) return snippets;
  return snippets.filter((s) => languages.includes(s.language.key));
};

export const filterCollections = function(filter: SnippetFilter, collections: SnippetCollection[]) {
  return collections
    .filter((collection) => {
      if (!filter.collections || !filter.collections.length) return true;
      return filter.collections.includes(collection.name);
    })
    .map((collection) => {
      return {
        ...collection,
        snippets: filterSnippets(filter, collection.snippets),
      };
    });
};

const filterByText = function(text: string, snippets: CodeSnippet[]) {
  if (!text) return snippets;
  return snippets.filter((snippet) => {
    let regex = new RegExp(text, "gi");
    return (
      regex.test(snippet.title) || regex.test(snippet.description) || regex.test(snippet.filename)
    );
  });
};

const filterByUser = function(user: string, snippets: CodeSnippet[]) {
  if (!user) return snippets;
  console.log("filter by user", user, snippets);
  return snippets.filter((s) => s.createdBy.username.toLowerCase() === user.toLowerCase());
};

export const filterSnippets = function(filter: SnippetFilter, snippets: CodeSnippet[]) {
  if (!filter) return snippets;
  let filteredSnippets = filterByLanguages(filter.languages, snippets);
  filteredSnippets = filterByTags(filter.tags, filteredSnippets);
  filteredSnippets = filterByText(filter.text, filteredSnippets);
  filteredSnippets = filterByUser(filter.user, filteredSnippets);
  return filteredSnippets;
};

const getUniqueTagsFromSnippets = function(snippets: CodeSnippet[] = []) {
  return Array.from(new Set(snippets.flatMap((s) => s.tags).map((t) => t.key))).map((t) => ({
    key: t,
  }));
};

export const getRefiners = function(snippets: CodeSnippet[]) {
  return {
    tags: orderBy(
      getUniqueTagsFromSnippets(snippets).map((t) => ({
        value: t.key,
        label: `${t.key}`,
      })),
      "label"
    ),
    users: orderBy(
      uniqBy(snippets.map((s) => s.createdBy).filter(Boolean), "key").map((user) => ({
        value: user.key,
        label: `${user.username}`,
      })),
      "label"
    ),
    collections: orderBy(
      uniqBy(
        snippets
          .map((s) => s.collection)
          .filter(Boolean)
          .map((collection) => ({
            value: collection,
            label: collection,
          })),
        "value"
      ),
      "label"
    ),
    languages: orderBy(
      uniqBy(snippets.map((s) => s.language).filter(Boolean), "key").map((l) => ({
        label: l.name,
        value: l.key,
      })),
      "label"
    ),
  };
};

export const getFilenameFromTitle = ({ title = "", language = "text" }, languageOptions = []) => {
  if (!title) return "";
  return kebabCase(title) + "." + getLanguageExtension(language, languageOptions);
};

const getLanguageExtension = function(language, languageOptions) {
  try {
    return languageOptions.find((l) => l.value === language).extensions[0];
  } catch (err) {
    return "txt";
  }
};
