import React, { useState, useEffect } from "react";
import Layout, { BaseScreenProps } from "./Layout";
import styled from "@emotion/styled";
import CodeEditor from "../components/code/CodeEditor";
import SnippetInfo from "../components/snippetDetails/SnippetInfo";
import SnippetForm from "../components/snippetDetails/SnippetForm";
import { snippetToSnippetInput } from "../data/snippetsData";
import {
  SecondaryButton,
  NeutralButton,
  PrimaryButton,
  RedButton,
} from "../components/primitives/Buttons";
import useSnippetDetails from "../hooks/useSnippetDetails";
import useLocation from "../hooks/core/useLocation";
import { LoadingPulse } from "../components/primitives/Loading";

export default function SnippetDetailsScreen({  }: SnippetDetailsScreenProps) {
  let { pathname } = useLocation();
  let [mode, setMode] = useState(Modes.DISPLAY);

  const {
    error,
    loading,
    snippet,
    canEdit,
    isDirty,
    onPropertyChange,
    onSave: saveChanges,
    onRemove: removeSnippet,
    discardChanges,
    snippetInput,
  } = useSnippetDetails(pathname);

  const onCancel = () => {
    discardChanges();
    setMode(Modes.DISPLAY);
  };
  const onSave = async () => {
    setMode(Modes.LOADING);
    await saveChanges();
    setMode(Modes.DISPLAY);
  };
  const onRemove = async () => {
    setMode(Modes.LOADING);
    await removeSnippet();
  };

  let renderCommands = () => {
    let commands = [
      {
        name: "remove",
        test: () => canEdit,
        render: () => (
          <RedButton key="remove-btn" onClick={() => onRemove()}>
            Remove
          </RedButton>
        ),
      },
      {
        name: "loading",
        test: () => loading || mode === Modes.LOADING,
        render: () => <LoadingPulse />,
      },
      {
        name: "edit",
        test: () => canEdit && mode === Modes.DISPLAY,
        render: () => (
          <SecondaryButton key="edit-btn" onClick={() => setMode(Modes.EDIT)}>
            Edit
          </SecondaryButton>
        ),
      },
      {
        name: "save",
        test: () => canEdit && isDirty && mode === Modes.EDIT,
        render: () => (
          <SecondaryButton key="save-btn" onClick={onSave}>
            Save
          </SecondaryButton>
        ),
      },
      {
        name: "cancel",
        test: () => canEdit && mode === Modes.EDIT,
        render: () => (
          <NeutralButton key="cancel-btn" onClick={onCancel}>
            Cancel
          </NeutralButton>
        ),
      },
    ];
    return loading || mode === Modes.LOADING ? (
      <LoadingPulse />
    ) : (
      <React.Fragment>{commands.filter((c) => c.test()).map((c) => c.render())}</React.Fragment>
    );
  };
  return (
    <Layout commands={renderCommands()}>
      <React.Fragment>
        {snippet && !loading && (
          <StyledContainer>
            <CodeEditor
              language={snippetInput ? snippetInput.language : snippet.language.key}
              code={snippetInput ? snippetInput.code : snippet.code}
              readOnly={mode === Modes.DISPLAY}
              onChange={(code) => onPropertyChange("code", code)}
            />
            {!loading && mode === Modes.DISPLAY && <SnippetInfo snippet={snippet} />}
            {!loading && (mode === Modes.EDIT || mode === Modes.LOADING) && (
              <SnippetForm
                snippet={snippetToSnippetInput(snippet)}
                onPropertyChange={onPropertyChange}
                disabled={mode === Modes.LOADING}
              />
            )}
          </StyledContainer>
        )}
      </React.Fragment>
    </Layout>
  );
}

const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  box-sizing: border-box;
  height: 100%;
  /* grid-gap: 10px; */
`;

enum Modes {
  DISPLAY = "display",
  EDIT = "edit",
  LOADING = "loading",
}

export interface SnippetDetailsScreenProps extends BaseScreenProps {
  collectionName?: string;
  username?: string;
  snippetName?: string;
}
