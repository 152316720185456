import React from "react";
import styled from "@emotion/styled";
import { SnippetUser } from "../../data/interfaces";
import { SquareCard, StyledSquareCardContainer } from "../primitives/Cards";

const UserCards = function({ users }: { users: SnippetUser[] }) {
  return (
    <StyledUserCards>
      {users.map((user) => (
        <UserCard key={user.key} user={user} />
      ))}
    </StyledUserCards>
  );
};

export default UserCards;

const StyledUserCards = styled(StyledSquareCardContainer)`
  .square-card .description .number {
    font-size: 1.15em;
    font-weight: bold;
  }
`;
export function UserCard({ user }: { user: SnippetUser }) {
  return (
    <SquareCard
      title={user.username}
      thumbnail={user.profilePhoto}
      url={"/" + user.key}
      description={
        <div>
          <span className="number">{user.snippetCount}</span> snippets
        </div>
      }
    />
  );
}
