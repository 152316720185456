import { useState, useEffect } from "react";
import { useApolloClient } from "react-apollo-hooks";
import cache, { CacheOptions } from "../../utils/cache";
import { isEqual } from "apollo-utilities";

// 30 day cache should be okay since we refresh it lazily on load
const cacheDuration = 1000 * 60 * 60 * 24 * 30;
export default function useApolloQueryWithCache(
  apolloQuery,
  cacheKeySuffix,
  delay = 2000,
  cacheLocation = localStorage
) {
  const cacheOptions: CacheOptions = {
    key: "code-snippets-" + cacheKeySuffix,
    location: cacheLocation,
    duration: cacheDuration,
  };
  let [data, setData] = useState(() => cache.get(cacheOptions));
  const apolloClient = useApolloClient();
  useEffect(() => {
    let cachedData = cache.get(cacheOptions);
    if (!isEqual(data, cachedData)) {
      console.log("set data", cachedData);
      setData(cachedData);
    }
  }, [data, cacheKeySuffix]);
  useEffect(() => {
    let isUnmounted = false;
    let doAsync = async () => {
      let { data } = await apolloClient.query({
        fetchPolicy: "network-only",
        ...apolloQuery,
      });

      if (!isUnmounted && !isEqual(data, cache.get(cacheOptions))) {
        console.log("set data", data);
        setData(data);
        cache.set(cacheOptions, data);
      }
    };
    let networkDelay = cache.get(cacheOptions) ? delay : 0;
    if (networkDelay !== -1) setTimeout(doAsync, networkDelay);
    return () => (isUnmounted = true);
  }, [JSON.stringify(apolloQuery)]);

  return data;
}
