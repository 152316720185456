import React from "react";
import styled from "@emotion/styled";
import { lighten, darken } from "polished";

export default function SnippetInfoProperty({ label, children }) {
  return (
    <StyledSnippetInfoProperty>
      <div className="label">{label}</div>
      <div className="value">{children}</div>
    </StyledSnippetInfoProperty>
  );
}

const StyledSnippetInfoProperty = styled.div`
  font-size: 0.85rem;
  .label {
    font-family: var(--monospace);
    text-transform: uppercase;
    color: ${(props) => darken(0.2, props.theme.light)};
    font-size: 0.8rem;
    margin-bottom: 2px;
  }
  .tags {
    margin-top: 6px;
  }
  pre {
    margin: 3px 0 0 0;
    background: var(--monacoblack);
    padding: 10px 5px;
    font-size: 12px;
    max-width: 100%;
    white-space: pre-wrap;
    /* border-radius: 5px; */
  }
  a.language {
    /* color: var(--primary-light); */
    font-family: var(--monospace);
    font-size: 16px;
  }
`;
