import React from "react";
import CommandBar from "../components/pageHeader/CommandBar";
import styled from "@emotion/styled";

function Layout(props: AppScreenProps) {
  let { children, className = "", commands: actions, hideCommandBar = false } = props;

  return (
    <StyledScreen className={"screen " + className}>
      {hideCommandBar !== true && <CommandBar actions={actions} />}
      <div className="content">{children}</div>
    </StyledScreen>
  );
}

export default React.memo(Layout);

export interface AppScreenProps {
  /** decorate the screen with an extra class */
  className?: string;
  commands?: JSX.Element;
  hideCommandBar?: boolean;
  /** Screen's main content */
  children: JSX.Element;
}

export interface BaseScreenProps {
  path?: string;
  location?: Location;
  default?: boolean;
}

const StyledScreen = styled.div`
  position: relative;
  z-index: 1;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas: "command-bar" "content";

  .content {
    grid-area: "content";
  }
`;

export const StyledResultsLayout = styled.div`
  display: grid;
  grid-template-columns: 300px 1fr;
  box-sizing: border-box;
  height: 100%;
`;

export const StyledResults = styled.div`
  position: relative;
  background: var(--black-transparent);
  border-top: 1px solid #555;
  padding: 20px 20px;
`;
