import React from "react";
import { Link } from "@reach/router";
import styled from "@emotion/styled";
import { LeftPanel } from "../components/primitives/Container";
import { NavLink, checkIsActive } from "../components/pageHeader/Header";
import { DocsZone, DocHeading } from "./docsNav";
import useLocation from "../hooks/core/useLocation";

function Sidebar({ navigation, childHeadings = [] }: SidebarProps) {
  let location = useLocation();
  return (
    <StyledSidebar>
      <ul>
        {navigation.map((link) => {
          let isActive = checkIsActive(location, link.path);
          return (
            <li
              key={link.path}
              className={["sidebar-item", isActive ? "active" : ""].filter((c) => c).join(" ")}
            >
              <NavLink path={link.path}>{link.title}</NavLink>
              {isActive && childHeadings && (
                <ul>
                  {childHeadings.map((childLink) => {
                    return (
                      <li>
                        <a href={"#" + childLink.id}>{childLink.title}</a>
                        {childLink.children.length > 0 && (
                          <ul>
                            {childLink.children.map((grandChild) => (
                              <li>
                                <a href={"#" + grandChild.id}>{grandChild.title}</a>
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                    );
                  })}
                </ul>
              )}
            </li>
          );
        })}
      </ul>
    </StyledSidebar>
  );
}

export default Sidebar;

const StyledSidebar = styled(LeftPanel)`
  position: relative;
  background: var(--black-transparent);
  ul {
    padding-left: 0;
    /* padding-right: 10px; */
    list-style: none;
    li.sidebar-item {
      font-size: 1.2rem;
      padding: 10px;
      border: 1px solid transparent;
      &.active {
        margin-right: -21px;
        /* background: #171519; */
        background: #202323;

        border: 1px solid #555;
        border-right: none;
      }
      > ul {
        padding-left: 5px;
        border-right: 1px solid #555;
        li {
          margin: 13px;
          font-size: 0.95rem;
          font-weight: bold;
          ul {
            list-style: initial;
            padding-left: 4px;
            li {
              margin: 10px;
              font-weight: 200;
              font-size: 0.9rem;
              color: var(--light);
            }
          }
        }
      }
    }
  }
`;
export interface SidebarProps {
  navigation: DocsZone[];
  childHeadings?: DocHeading[];
}
