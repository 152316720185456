import React from "react";
import ReactDOM from "react-dom";
import { ApolloClient } from "apollo-client";
import { HttpLink } from "apollo-link-http";
import { InMemoryCache, defaultDataIdFromObject } from "apollo-cache-inmemory";
import { ApolloProvider } from "react-apollo";
import { ApolloProvider as ApolloHooksProvider } from "react-apollo-hooks";
import { setContext } from "apollo-link-context";
import App from "./screens/App";
import { ensureLogin, redirectToLogin } from "./auth/auth";
import { getServerlessUrl, getEnv } from "./utils/config";

ensureLogin().then((accessToken) => {
  const httpLink = new HttpLink({ uri: getServerlessUrl() + "/graphql" });

  const authLink = setContext((_, { headers }) => {
    let updatedHeaders = accessToken
      ? { ...headers, authorization: `Bearer ${accessToken}` }
      : headers;
    return {
      headers: updatedHeaders,
    };
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache({
      dataIdFromObject: getApolloCacheKey,
    }),
  });

  ReactDOM.render(
    <ApolloProvider client={client}>
      <ApolloHooksProvider client={client}>
        <App />
      </ApolloHooksProvider>
    </ApolloProvider>,
    document.getElementById("root")
  );
});

const getApolloCacheKey = function(object) {
  switch (object.__typename) {
    case "Collection":
      return "Collection:" + object.path;
    default:
      return object.key ? `${object.__typename}:${object.key}` : defaultDataIdFromObject(object);
  }
};
