export const getEnv = function() {
  if (
    window.location.origin.indexOf("localhost") > -1 ||
    window.location.origin.indexOf("127.0.0.1") > -1
  ) {
    return "local";
  }
  if (window.location.origin.indexOf("dev.codesnippets.dev") > -1) {
    return "dev";
  }
  return "prod";
};
export const getGraphQLServer = () => {
  return getServerlessUrl() + "/graphql";
};

export const getServerlessUrl = (env = getEnv()) => {
  let urls = {
    local: "http://localhost:5000/devcodesnippetsdotdev/us-central1",
    // local: "https://us-central1-devcodesnippetsdotdev.cloudfunctions.net",
    dev: "https://us-central1-devcodesnippetsdotdev.cloudfunctions.net",
    prod: "https://us-central1-codesnippetsdotdev.cloudfunctions.net",
  };
  return urls[env];
};

export const getWebUrl = (env = getEnv()) => {
  let webUrls = {
    local: "http://localhost:8888",
    dev: "https://dev.codesnippets.dev",
    prod: "https://codesnippets.dev",
  };
  return webUrls[env];
};
