import React, { useState, useEffect } from "react";
import { PickerMulti, PickerSingle } from "./Picker";
import { SelectOption } from "../../data/interfaces";

function LanguagePicker({
  value,
  onChange,
  required = false,
  isMulti = false,
  options = [],
  creatable = false,
}: LanguagePickerProps) {
  let Picker = isMulti ? PickerMulti : PickerSingle;
  return (
    <Picker
      label="Language"
      name="language"
      onChange={onChange}
      value={value as any}
      options={options}
      required={required}
      creatable={creatable}
    />
  );
}

export default React.memo(LanguagePicker);

export interface LanguagePickerProps {
  value: string | string[];
  onChange: (value: string | string[]) => void;
  options: SelectOption[];
  required?: boolean;
  isMulti?: boolean;
  creatable?: boolean;
}
