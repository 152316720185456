import React, { useCallback, useState, useEffect, useRef } from "react";
import FormControl from "../primitives/FormControl";
import useDebounce from "../../hooks/core/useDebounce";

export default function Textbox({
  label,
  id,
  value,
  onChange,
  disabled = false,
  required = false,
  multiline = false,
  debounce = 300,
  autofocus = false,
  placeholder = "",
}) {
  let elemRef = useRef(null);
  let [inputValue, setInputValue] = useState(value);
  useDebounce(
    () => {
      if (value != inputValue) {
        onChange(id, inputValue);
      }
    },
    debounce,
    [inputValue]
  );

  useDebounce(
    () => {
      if (value !== inputValue) {
        setInputValue(value);
      }
    },
    600,
    [value]
  );

  useEffect(() => {
    if (autofocus && elemRef.current) {
      elemRef.current.focus();
    }
  }, []);

  let inputProps = {
    id,
    value: inputValue,
    onChange: (e) => setInputValue(e.target.value),
    disabled,
    required,
    placeholder,
    ref: elemRef,
    autoComplete: "off",
  };

  return (
    <FormControl key={id}>
      <label htmlFor={id} className={required ? "required" : ""}>
        {label}
      </label>
      {multiline === true ? <textarea rows={8} {...inputProps} /> : <input {...inputProps} />}
    </FormControl>
  );
}
