import React from "react";
import styled from "@emotion/styled";

import hearbeatLoader from "../../images/heartbeat-loader.gif";

export function LoadingPulse({ width = 100, opacity = 0.6 }) {
  return (
    <StyledContainer style={{ width: width + "px" }}>
      <img src={hearbeatLoader} style={{ opacity: opacity }} />
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    max-width: 100%;
  }
`;
