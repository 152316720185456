import React from "react";
import styled from "@emotion/styled";
import { darken, transparentize } from "polished";

export const BlackContainer = styled.div`
  background: var(--black);
  padding: 10px;
  /* border: 1px solid #484848; */
`;

export const BlackTransparentContainer = styled(BlackContainer)`
  background: ${(props) => transparentize(0.25, props.theme.black)};
`;

export const BorderedDiv = styled.div`
  border: 1px solid #555;
  border-color: ${(props) => transparentize(0.8, "#fff")};
`;

export const RightPanel = styled.div`
  background: var(--blackgreen);
  /* padding: 10px; */
  padding: 20px 10px 10px 10px;
  border-left: 1px solid #555;
  border-top: 1px solid #555;
  border-color: ${() => transparentize(0.8, "#fff")};
  > * {
    margin-bottom: 20px;
  }
`;

export const LeftPanel = styled(RightPanel)`
  border-left: none;
  border-right: 1px solid #555;
  border-color: ${() => transparentize(0.8, "#fff")};
  border-bottom: none;
`;

export const StyledTabHeader = styled.h1`
  display: inline-block;
  padding: 4px 10px 2px;
  border: 1px solid #555;
  border-bottom: none;
  border-radius: 3px 3px 0 0;
  color: #68948a;
  text-transform: lowercase;
  font-size: 1.6rem;
  /* letter-spacing: 0.8px; */
  margin-bottom: 8px !important;
  a {
    color: #68948a;
  }
  a:hover {
    color: var(--light);
  }
`;
