import React from "react";
import { PickerMulti } from "./Picker";
import { SelectOption } from "../../data/interfaces";

function TagPicker({ values = [], onChange, creatable = false, options = [] }: TagPickerProps) {
  return (
    <PickerMulti
      label="Tags"
      name="tags"
      onChange={onChange}
      value={values}
      options={options}
      creatable={creatable}
    />
  );
}

export default React.memo(TagPicker);

export interface TagPickerProps {
  values: string[];
  onChange: (tags: string[]) => void;
  creatable?: boolean;
  options?: SelectOption[];
}
