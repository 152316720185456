import React from "react";
import { StateProvider } from "@appbaseio/reactivesearch";
import styled from "@emotion/styled";

export default function ResultStats() {
  return (
    <StateProvider
      render={({ searchState }) => {
        if (!searchState || !searchState.results || !searchState.results.hits) return null;
        return (
          <ResultStatsText
            showing={searchState.results.hits.hits.length}
            total={searchState.results.hits.total}
          />
        );
      }}
    />
  );
}

function ResultStatsText({ total = 0, showing = 0 }) {
  let text = "";
  if (total === showing) {
    text = total + " results found";
  } else {
    text = `Showing ${showing} of ${total} results`;
  }
  return <StyledResultStats className="result-stats">{text}</StyledResultStats>;
}

const StyledResultStats = styled.div`
  font-size: 0.8em;
`;
