import { getTokenFromCache, checkIsLoggedIn } from "../auth/auth";
import gql from "graphql-tag";
import useApolloQueryWithCache from "./apollo/useApolloQueryWithCache";
import { useContext } from "react";
import React from "react";

export const CurrentUserContext = React.createContext({
  isLoggedIn: false,
  loading: true,
  user: null,
});

export function useCurrentUser() {
  return useContext(CurrentUserContext);
}
export default function useCurrentUserData() {
  let data = useApolloQueryWithCache({ query: GET_CURRENT_USER }, getTokenFromCache(), -1);
  return {
    isLoggedIn: checkIsLoggedIn(),
    user: (data ? data.me : null) as CurrentUser,
    loading: !data,
  };
}

let GET_CURRENT_USER = gql`
  query GetCurrentUser {
    me {
      key
      username
      name
      profilePhoto
    }
  }
`;
export interface CurrentUser {
  key: string;
  username: string;
  name: string;
  profilePhoto: string;
  token: string;
}
