import React from "react";
import { DataSearch, MultiList, DateRange, SingleDropdownList } from "@appbaseio/reactivesearch";

export const componentIds = ["query", "tags", "author", "created", "language", "collection"];

export const getRefinerPropOverrides = function(value: any, isMultivalue = true) {
  if (!value) return {};

  return {
    value: isMultivalue ? [value] : value,
    key: value,
    URLParams: false,
    style: { display: "none" },
    showFilter: false,
  };
};
export function TextSearch(props: any) {
  return (
    <DataSearch
      title="Full text search"
      dataField={[
        "title",
        "filename",
        "description",
        "code",
        "tags.keyword",
        "language",
        "collection",
      ]}
      fieldWeights={[8, 5, 2, 1, 2, 2, 3]}
      componentId="query"
      autosuggest={false}
      debounce={300}
      showIcon={false}
      placeholder="Search..."
      URLParams={true}
      showClear={true}
      {...props}
    />
  );
}

export function LanguageRefiner(props: any) {
  const componentId = "language";
  return (
    <MultiList
      title="Language"
      filterLabel="Language"
      componentId={componentId}
      dataField="language"
      placeholder="Filter..."
      showSearch={false}
      showCheckbox={true}
      react={{
        and: componentIds.filter((c) => c !== componentId),
      }}
      URLParams={true}
      showCount={true}
      size={6}
      {...props}
    ></MultiList>
  );
}

export function CollectionRefiner(props: any) {
  const componentId = "collection";
  return (
    <SingleDropdownList
      title="Collection"
      filterLabel="Collection"
      componentId={componentId}
      dataField="collection.keyword"
      showSearch={false}
      showCheckbox={true}
      react={{
        and: componentIds.filter((c) => c !== componentId),
      }}
      URLParams={true}
      showCount={true}
      {...props}
    ></SingleDropdownList>
  );
}

export function AuthorRefiner(props: any) {
  const componentId = "author";
  return (
    <MultiList
      title="Author"
      filterLabel="Author"
      componentId={componentId}
      dataField="createdBy.keyword"
      showSearch={false}
      showCheckbox={true}
      react={{
        and: componentIds.filter((c) => c !== componentId),
      }}
      URLParams={true}
      showCount={true}
      size={6}
      {...props}
    ></MultiList>
  );
}

export function CreatedDateRefiner(props: any) {
  let componentId = "created";
  return (
    <DateRange
      title="Created Date"
      componentId="created"
      dataField="created"
      filterLabel="Created"
      URLParams={true}
      react={{
        and: componentIds.filter((c) => c !== componentId),
      }}
    />
  );
}

export function TagsRefiner(props: any) {
  const componentId = "tags";
  return (
    <MultiList
      title="Tags"
      filterLabel="Tags"
      placeholder="Filter..."
      componentId={componentId}
      dataField="tags.keyword"
      showSearch={false}
      showCheckbox={true}
      react={{
        and: componentIds.filter((c) => c !== componentId),
      }}
      URLParams={true}
      showCount={true}
      size={6}
      {...props}
    ></MultiList>
  );
}
