/* @jsx mdx */
import React from 'react';
import { mdx } from '@mdx-js/react'
/* @jsx mdx */


const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`VS Code Extension`}</h1>
    <p>{`This is how you can use CodeSnippets.dev inside of VS Code. The extension is not in the public marketplace yet, so for now, the install process is a little manual but it still only take 3 minutes.`}</p>
    <h2>{`Installation / Setup`}</h2>
    <p>{`Until it hits the public VS Code Marketplace, you'll need to download the `}<inlineCode parentName="p">{`vsix`}</inlineCode>{` file and run a terminal command to install it.
`}<img parentName="p" {...{
        "src": "https://res.cloudinary.com/droopytersen/image/upload/v1561001710/codesnippets/codesnippets-install.gif",
        "alt": "Install Demo"
      }}></img></p>
    <ol>
      <li parentName="ol">{`Download the `}<a parentName="li" {...{
          "href": "/vscode-extension/codesnippets-vscode.vsix"
        }}>{`latest version of the extension`}</a></li>
      <li parentName="ol">{`Open a terminal to the same directory as the downloaded `}<inlineCode parentName="li">{`.vsix`}</inlineCode>{` file and run the following command`}</li>
    </ol>
    <pre><code parentName="pre" {...{}}>{`code --install-extension codesnippets-vscode.vsix
`}</code></pre>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Go to `}<a parentName="li" {...{
          "href": "https://codesnippets.dev/token"
        }}>{`https://codesnippets.dev/token`}</a>{` and copy your access token`}</li>
      <li parentName="ol">{`Reopen VSCode`}</li>
      <li parentName="ol">{`Open the command palette (`}<inlineCode parentName="li">{`CRTL+SHIFT+P`}</inlineCode>{`) and choose `}<strong parentName="li">{`CodeSnippets: Login w/ Access Token`}</strong></li>
      <li parentName="ol">{`Paste in your access token (from Step 4)`}</li>
    </ol>
    <p><img parentName="p" {...{
        "src": "https://res.cloudinary.com/droopytersen/image/upload/v1560185265/codesnippets/LoginCommand.png",
        "alt": "Login Command"
      }}></img></p>
    <h2>{`Using Snippets`}</h2>
    <p>{`When working in VSCode, you can quickly pull in your snippets from `}<a parentName="p" {...{
        "href": "https://codesnippets.dev"
      }}>{`CodeSnippets.dev`}</a>{`.`}</p>
    <h3>{`Insert a snippet at the current selection`}</h3>
    <p>{`TODO: Document and add GIF demo`}</p>
    <h3>{`Import a snippet as a new file`}</h3>
    <p>{`TODO: Document and add GIF demo`}</p>
    <h2>{`Creating Snippets`}</h2>
    <p>{`You can create new snippets from inside of VSCode.`}</p>
    <h3>{`Create a new snippet from selection`}</h3>
    <p>{`TODO: Document and add GIF demo`}</p>
    <h3>{`Create a new snippet from a file`}</h3>
    <p>{`TODO: Document and add GIF demo`}</p>
    <h2>{`Placeholder Syntax`}</h2>
    <p>{`TODO: Document and add GIF demo`}</p>
    <h3>{`Create a snippet with placeholders`}</h3>
    <p>{`TODO: Document and add GIF demo`}</p>
    <h3>{`Insert a snippet and fill in placeholders`}</h3>
    <p>{`TODO: Document and add GIF demo`}</p>
    </MDXLayout>;
}

MDXContent.isMDXComponent = true;
