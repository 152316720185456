import React, { useState } from "react";
import styled from "@emotion/styled";
import { Link, navigate } from "@reach/router";
import { BigPrimaryButton } from "../primitives/Buttons";
import Logo from "./Logo";
import CurrentUserActions from "./CurrentUserActions";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import useLocation from "../../hooks/core/useLocation";
import { redirectToLogin } from "../../auth/auth";
import GlobalSearch from "./GlobalSearch";

function Header() {
  let { user, isLoggedIn } = useCurrentUser();
  let currentUser = user && user.username ? user.username.toLowerCase() : "";
  return (
    <StyledHeader className="page-header">
      <div className="nav-left">
        <Logo />
        <nav className="nav-links left">
          {currentUser && <NavLink path={`/${currentUser}`}>My Snippets</NavLink>}
          <NavLink path="/docs/vscode">How To</NavLink>
          <NavLink path="/explore">Explore</NavLink>
        </nav>
      </div>
      <div className="search">
        <GlobalSearch />
      </div>
      <div className="nav-right nav-links">
        {currentUser && isLoggedIn && (
          <Link to="/new-snippet" title="Create a new Code Snippet">
            <BigPrimaryButton>+Add Snippet</BigPrimaryButton>
          </Link>
        )}
        {!isLoggedIn && (
          <BigPrimaryButton onClick={() => redirectToLogin()}>Login w/ Github</BigPrimaryButton>
        )}
        <CurrentUserActions />
      </div>
    </StyledHeader>
  );
}
export default React.memo(Header);

const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: relative;
  z-index: 2;
  padding: 10px 10px;
  font-family: var(--monospace);
  background: var(--black-transparent);
  /* height: 57px; */
  box-sizing: border-box;
  flex-wrap: wrap;
  .search {
    flex: 1 1 500px;
    min-width: 400px;
    margin: 10px 0;
  }
  .nav-left,
  .nav-right {
    text-transform: uppercase;
    display: flex;
    align-items: center;
  }
  .nav-links {
    display: flex;
    align-items: center;
    > a {
      margin-right: 10px;
      padding: 5px 10px;
      color: var(--light);
      text-decoration: none;
      border-bottom: 2px solid transparent;
    }
    > a.active {
      color: var(--white);
      border-bottom-color: var(--primary);
    }
  }
`;

export const checkIsActive = (location, path) => {
  // Current window.location.pathname must start with NavLink path
  return location.pathname.toLowerCase().indexOf(path.toLowerCase()) === 0;
};

export const NavLink = ({ path, children }) => {
  let location = useLocation();
  let cssClass = ["link", checkIsActive(location, path) ? "active" : ""].filter((c) => c).join(" ");
  return (
    <Link className={cssClass} to={path}>
      {children}
    </Link>
  );
};
