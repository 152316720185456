/* @jsx mdx */
import React from 'react';
import { mdx } from '@mdx-js/react'
/* @jsx mdx */


const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`CodeSnippets.dev`}</h1>
    <p>{`With `}<a parentName="p" {...{
        "href": "https://codesnippets.dev"
      }}>{`CodeSnippets.dev`}</a>{`, you can accumulate a `}<strong parentName="p">{`personal collection`}</strong>{` of reliable `}<strong parentName="p">{`code snippets`}</strong>{` and quickly inject them into your projects.`}</p>
    <p>{`Instead of digging through old source code or repeatedly googling the same thing, `}<a parentName="p" {...{
        "href": "/docs/vscode#creating-snippets"
      }}>{`create a CodeSnippet`}</a>{`.`}</p>
    <p>{`The minutes you save with each snippet use can add up to hours per week. But more importantly, CodeSnippets will save you TONS mental energy.`}</p>
    <blockquote>
      <p parentName="blockquote">{`CodeSnippets will save you TONS mental energy`}</p>
    </blockquote>
    <h2>{`What is it?`}</h2>
    <pre><code parentName="pre" {...{}}>{`CODE_SNIPPETS = GITHUB_GISTS + SEXY_WEB_APP + VS_CODE_EXTENSION
`}</code></pre>
    <h2>{`Example Use Cases`}</h2>
    <p>{`At some point during every web development project, I end up googling `}<a parentName="p" {...{
        "href": "https://stackoverflow.com/questions/901115/how-can-i-get-query-string-values-in-javascript"
      }}>{`"javascript get querystring value"`}</a>{` and copy and paste the same
StackOverflow answer. `}<strong parentName="p">{`This is the perfect candidate for a CodeSnippets.dev snippet.`}</strong></p>
    <p>{`The snippet posibilities are endless and personal, but here are some examples to get the wheels turning:`}</p>
    <ul>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`javascript`}</inlineCode>{` DOM utility functions you copy and paste across all your projects.`}</li>
      <li parentName="ul">{`The 3 lines of `}<inlineCode parentName="li">{`CSS`}</inlineCode>{` to do a fade `}<inlineCode parentName="li">{`transition`}</inlineCode>{` that you always have to Google.`}</li>
      <li parentName="ul">{`The litte tweaks you need to do a `}<inlineCode parentName="li">{`CORS`}</inlineCode>{` request for `}<inlineCode parentName="li">{`JSON`}</inlineCode>{` with the Fetch API.`}</li>
      <li parentName="ul">{`Storing a MongoDB `}<inlineCode parentName="li">{`map-reduce`}</inlineCode>{` example, so you don't have to completely relearn it next time you need it again.`}</li>
      <li parentName="ul">{`The React component boilerplate, one for class components and one for function components.`}</li>
    </ul>
    </MDXLayout>;
}

MDXContent.isMDXComponent = true;
