import useApolloQuery from "./apollo/useApolloQuery";
import { CodeSnippet, SaveSnippetInput } from "../data/interfaces";
import gql from "graphql-tag";
import useApolloMutation from "./apollo/useApolloMutation";
import useApolloQueryWithCache from "./apollo/useApolloQueryWithCache";

export const useFetchSnippet = function(snippetPath, forceUpdate = false) {
  // let { data, error, loading } = useApolloQueryWithCache(SNIPPET_BY_PATH_QUERY, {
  //   variables: { path: snippetPath },
  // });
  let data = useApolloQueryWithCache(
    {
      query: SNIPPET_BY_PATH_QUERY,
      fetchPolicy: "cache-first",
      variables: { path: snippetPath },
      forceUpdate: forceUpdate ? Date.now() : false,
    },
    snippetPath,
    1,
    sessionStorage
  );
  let snippet = data ? (data.snippet as CodeSnippet) : null;

  return {
    snippet,
    error: "",
    loading: !snippet,
  };
};

export const useSaveSnippet = function(snippet: SaveSnippetInput) {
  return useApolloMutation(SAVE_SNIPPET_MUTATION, {
    variables: {
      snippet,
    },
  });
};

export const useRemoveSnippet = function(key: string) {
  return useApolloMutation(REMOVE_SNIPPET_MUTATION, {
    variables: {
      key,
    },
  });
};

const SAVE_SNIPPET_MUTATION = gql`
  mutation SaveSnippet($snippet: SaveSnippetInput!) {
    saveSnippet(snippet: $snippet) {
      path
      key
      title
      filename
      description
      created
      modified
      collection
      language {
        name
        key
      }
      createdBy {
        name
        key
        profilePhoto
        username
      }
      tags {
        key
      }
      code
    }
  }
`;

const REMOVE_SNIPPET_MUTATION = gql`
  mutation RemoveSnippet($key: String!) {
    removeSnippet(key: $key)
  }
`;

const SNIPPET_BY_PATH_QUERY = gql`
  query GetSnippetByPath($path: String!) {
    snippet(path: $path) {
      path
      key
      title
      filename
      description
      created
      modified
      collection
      language {
        name
        key
      }
      createdBy {
        name
        key
        profilePhoto
        username
      }
      tags {
        key
      }
      code
    }
  }
`;
