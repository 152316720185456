import React from "react";
import Cli from "./CLI.mdx";
import GettingStarted from "./GettingStarted.mdx";
import Introduction from "./Introduction.mdx";
import VSCode from "./VSCode.mdx";
const navigation: DocsZone[] = [
  {
    path: "/docs/intro",
    title: "Introduction",
    Mdx: Introduction,
  },
  {
    path: "/docs/getting-started",
    title: "Getting Started",
    Mdx: GettingStarted,
  },
  {
    path: "/docs/vscode",
    title: "Visual Studio Code",
    Mdx: VSCode,
  },
  {
    path: "/docs/cli",
    title: "Command Line (CLI)",
    Mdx: Cli,
  },
];

export default navigation;

export interface DocsZone {
  path: string;
  title: string;
  Mdx: any;
  children?: DocHeading[];
}

export interface DocHeading {
  id: string;
  title: string;
  children?: DocHeading[];
}
