import React, { useState } from "react";
import CreatableSelect from "react-select/Creatable";
import Select from "react-select";
import StyledSelect from "../primitives/StyledSelect";
import useDebounce from "../../hooks/core/useDebounce";

export function PickerSingle(props: PickerSingleProps) {
  let SelectComponent = props.creatable === true ? CreatableSelect : Select;
  let [selected, setSelected] = useState(props.value || "");

  useDebounce(
    () => {
      props.onChange(selected);
    },
    150,
    [selected]
  );

  return (
    <StyledSelect>
      {props.label && (
        <label htmlFor={name} className={props.required ? "required" : ""}>
          {props.label}
        </label>
      )}
      <SelectComponent
        className="react-select"
        classNamePrefix="react-select"
        value={
          props.options.find((o) => o.value === selected) || { value: selected, label: selected }
        }
        onChange={(option) => setSelected(option.value)}
        options={props.options}
        name={name}
      />
    </StyledSelect>
  );
}

export function PickerMulti(props: PickerMultiProps) {
  let SelectComponent = props.creatable === true ? CreatableSelect : Select;
  let [selected, setSelected] = useState(props.value || []);

  useDebounce(
    () => {
      props.onChange(selected);
    },
    300,
    [selected]
  );
  let selectedOptions = selected.map((val: string) => {
    return props.options.find((o) => o.value === val) || { value: val, label: val };
  });
  return (
    <StyledSelect>
      {props.label && (
        <label htmlFor={name} className={props.required ? "required" : ""}>
          {props.label}
        </label>
      )}
      <SelectComponent
        className="react-select"
        classNamePrefix="react-select"
        value={selectedOptions}
        onChange={(options) => setSelected((options || []).map((o) => o.value))}
        options={props.options}
        name={name}
        isMulti={true}
        isClearable={true}
      />
    </StyledSelect>
  );
}

export interface PickerOption {
  value: string;
  label: string;
}

export interface PickerProps {
  creatable?: boolean;
  options: PickerOption[];
  name: string;
  required?: boolean;
  label: string;
}

export interface PickerSingleProps extends PickerProps {
  value: string;
  onChange: (newValue: string) => void;
}
export interface PickerMultiProps extends PickerProps {
  value: string[];
  onChange: (newValues: string[]) => void;
}
