import React from "react";
import styled from "@emotion/styled";
import { Link } from "@reach/router";
import useLocation from "../../hooks/core/useLocation";

const StyledBreadcrumb = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.8em;
  > div:last-child {
    /* font-size: 1.1rem; */
    font-weight: bold;
  }
  .separator {
    margin: 0 5px;
    color: var(--light);
  }
`;
export default function Breadcrumb() {
  let { pathname } = useLocation();
  let sections = pathname.split("/").filter(Boolean);

  let breadcrumbs = sections.map((section, index) => ({
    section,
    path: "/" + sections.slice(0, index + 1).join("/"),
  }));

  return (
    <StyledBreadcrumb>
      {breadcrumbs.map((breadcrumb) => (
        <div key={breadcrumb.path}>
          <span className="separator">/</span>
          <Link to={breadcrumb.path}>{decodeURIComponent(breadcrumb.section)}</Link>
        </div>
      ))}
    </StyledBreadcrumb>
  );
}
