import React from "react";
import { Link } from "@reach/router";
import styled from "@emotion/styled";
import { lighten, darken, transparentize } from "polished";
import { SnippetTag } from "../../data/interfaces";

function Tags({ tags }: { tags: SnippetTag[] }) {
  if (!tags) return null;
  return (
    <StyledTags className="tags">
      {tags.map((tag) => (
        <Tag key={tag.key} tag={tag} />
      ))}
    </StyledTags>
  );
}

export default React.memo(Tags);

export const Tag = function({ tag }: { tag: SnippetTag }) {
  return (
    <Link className="tag" title={`View '${tag.key}' snippets`} to={`/explore/tags/${tag.key}`}>
      <StyledTag key={tag.key}>
        <div>{tag.key}</div>
        {tag.snippetCount && <div className="count">{tag.snippetCount}</div>}
      </StyledTag>
    </Link>
  );
};

const StyledTags = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  /* margin-top: 5px; */
  & > * {
    margin-right: 5px;
    margin-bottom: 5px;
  }
`;
export const StyledTag = styled.div`
  /* padding: 4px 8px; */
  border-radius: 3px;
  background: var(--dark);
  color: var(--light);
  font-family: var(--monospace);
  display: flex;
  overflow: hidden;

  &:hover {
    background: ${(props) => lighten(0.05, props.theme.dark)};
    .count {
      background: var(--dark);
    }
  }
  > * {
    padding: 5px 8px;
  }
  .count {
    background: ${(props) => darken(0.05, props.theme.dark)};
    letter-spacing: -2px;
    color: ${(props) => transparentize(0.3, props.theme.light)};
  }
`;
