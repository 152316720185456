import React from "react";
import styled from "@emotion/styled";
import { Link } from "@reach/router";

export default function Logo() {
  return (
    <StyledLogo className="logo monospace">
      <Link to="/" title="CodeSnippets.dev">
        <span>&lt;</span>CodeSnippets<span>/&gt;</span>
      </Link>
    </StyledLogo>
  );
}
const StyledLogo = styled.div`
  font-size: 24px;
  letter-spacing: -2px;
  text-transform: none;
  margin-right: 20px;
  span {
    color: #888;
    &:first-child {
      margin-right: 4px;
    }
    &:last-child {
      margin-left: 4px;
    }
  }
  img {
    width: 200px;
    width: 145px;
    transform: rotate(5deg) translateY(-1px);
    opacity: 0.87;
    filter: grayscale(25%);
  }
  a:hover {
    img {
      opacity: 1;
    }
  }
`;
