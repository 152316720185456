import React, { useState, useEffect, useMemo } from "react";
import { PickerSingle } from "./Picker";
import { SelectOption } from "../../data/interfaces";

function UserPicker({ value = "", onChange, creatable = false, options = [] }: UserPickerProps) {
  options.unshift({
    value: "",
    label: "Anyone",
  });

  return (
    <PickerSingle
      label="Created By"
      name="user"
      onChange={onChange}
      value={value}
      options={options}
      creatable={creatable}
    />
  );
}

export default React.memo(UserPicker);

export interface UserPickerProps {
  value: string;
  onChange: (user: string) => void;
  creatable?: boolean;
  options: SelectOption[];
}
