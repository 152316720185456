import React from "react";
import styled from "@emotion/styled";
import { StyledTabHeader, BlackTransparentContainer } from "../primitives/Container";
import { Link } from "@reach/router";
import { darken, transparentize } from "polished";

export function SquareCard({ title, url, thumbnail, description, className = "" }) {
  return (
    <Link title="View user snippets" to={url} rel="prerender">
      <StyledSquareCard className={"square-card " + className}>
        <div className="thumbnail">
          <img src={thumbnail} />
        </div>
        <div className="title">{title}</div>
        {/* {user.name && <div className="name">{user.name}</div>} */}
        <div className="description">{description}</div>
      </StyledSquareCard>
    </Link>
  );
}

export const StyledCard = styled(BlackTransparentContainer)`
  border: 1px solid #888;
  border-color: ${() => transparentize(0.8, "#fff")};
  border-radius: 3px;
  background: var(--blackgreen);
  position: relative;
  padding: 15px;
  .title {
    /* font-family: var(--monospace); */
    font-size: 1.5rem;
    letter-spacing: 0.5px;
    margin-bottom: 15px;
    display: block;
    color: #fff;
  }
`;

export const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  grid-gap: 25px;
`;

export const StyledSquareCardContainer = styled(CardContainer)`
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-gap: 10px;
`;

export const StyledSquareCard = styled(StyledCard)`
  display: flex;
  align-items: center;
  flex-direction: column;
  &:hover {
    border-color: var(--primary-lighter);
  }
  .thumbnail {
    text-align: center;
    opacity: 0.8;
    img {
      border-radius: 50%;
      width: 80px;
    }
  }
  .title:hover .thumb {
    opacity: 1;
  }
  .description {
    position: relative;
  }
`;
