import React, { useLayoutEffect, useState } from "react";
import { DocHeading } from "./docsNav";

const addIdsToHeaders = function(container: Element, tag: string) {
  container.querySelectorAll(tag).forEach((node: Element) => {
    if (!node.id) {
      node.id = node.textContent
        .toLocaleLowerCase()
        .replace(/ /g, "-")
        .replace(/[^-a-z0-9-]+/i, "");
    }
  });
};

const parseHeadings = function(container: Element) {
  let h2s = getElements("h2,h3", container).reduce((h2s: any[], heading: Element) => {
    let docHeading = { title: heading.textContent, id: heading.id, children: [] };

    if (heading.tagName === "H2") {
      h2s.push(docHeading);
    } else {
      h2s[h2s.length - 1].children.push(docHeading);
    }

    return h2s;
  }, []);
  console.log("Headings!!!", h2s);
  return h2s;
};

export function getElements(selector, elem?) {
  return [].slice.call((elem || document).querySelectorAll(selector), 0);
}

export default function useHeadings(contentRef, docsPath): DocHeading[] {
  let [headings, setHeadings] = useState([]);
  useLayoutEffect(() => {
    // get all headers and add ids
    if (contentRef.current) {
      addIdsToHeaders(contentRef.current, "h1");
      addIdsToHeaders(contentRef.current, "h2");
      addIdsToHeaders(contentRef.current, "h3");
      addIdsToHeaders(contentRef.current, "h4");
      addIdsToHeaders(contentRef.current, "h5");
    }
    let newHeadings = parseHeadings(contentRef.current);
    setHeadings(newHeadings);
  }, [docsPath]);
  return headings;
}
