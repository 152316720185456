import React, { useEffect, useRef, useLayoutEffect, useState } from "react";
import styled from "@emotion/styled";
import Layout, { BaseScreenProps, StyledResults, StyledResultsLayout } from "./Layout";
import Sidebar from "../docs/Sidebar";
import docsNav, { DocHeading } from "../docs/docsNav";
import useHeadings from "../docs/useHeadings";
import { navigate } from "@reach/router";

function DocsScreen({ docsPath = "intro" }: DocsScreenProps) {
  // if (!docsPath) navigate("/docs/intro");
  let contentRef = useRef(null);
  let headings = useHeadings(contentRef, docsPath);

  let docZone = docsNav.find((screen) => screen.path === "/docs/" + docsPath);
  return (
    <Layout>
      <StyledResultsLayout>
        <Sidebar navigation={docsNav} childHeadings={headings} />
        <StyledContent>
          <StyledMdx className="mdx" ref={contentRef}>
            {docZone && <docZone.Mdx />}
          </StyledMdx>
        </StyledContent>
      </StyledResultsLayout>
    </Layout>
  );
}

export interface DocsScreenProps extends BaseScreenProps {
  docsPath?: string;
}

const StyledContent = styled(StyledResults)`
  padding: 10px 25px;
  background: #202323;
`;

const StyledMdx = styled.div`
  box-sizing: border-box;
  max-width: 800px;
  width: 100%;
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: var(--monospace);
  }
  h1 {
    margin: 20px 0 30px 0;
    font-family: var(--sans-serif);
    font-size: 2.5rem;
  }
  a {
    color: var(--primary-lighter);
  }
  h2 {
    /* color: var(--primary-light); */
    font-family: var(--sans-serif);
    margin: 60px 0 30px 0;
    padding-bottom: 10px;
    border-bottom: 1px solid dimgrey;
    font-size: 1.6rem;
  }

  h3 {
    font-family: var(--sans-serif);
    margin: 40px 0 20px 0;
    font-size: 1.25rem;
    color: var(--light);
  }
  p {
    color: var(--white);
    letter-spacing: 0.8px;
    font-size: 15px;
    line-height: 22px;
  }
  blockquote {
    word-wrap: break-word;
    border-left-width: 5px;
    border-left-style: solid;
    margin: 0;
    padding: 1px 16px 1px 10px;
    border-left-color: var(--primary);
    background: #333a3a;
  }
  code {
    /* padding: 3px 6px; */
    font-size: 14px;
    color: #d7ba7d;
  }
  img {
    max-width: 100%;
  }
  pre {
    background: var(--blackgreen);
    margin: 20px 0 40px 0;
    box-sizing: border-box;
    width: 100%;
    white-space: pre-wrap;
    word-wrap: break-word;
    background-color: rgba(10, 10, 10, 0.4);
    white-space: pre-wrap;
    padding: 16px;
    border-radius: 3px;
    overflow: auto;
    display: block;
    code {
      padding: 0;
      font-size: 0.95rem;
      color: var(--light);
    }
  }
  li {
    margin-bottom: 8px;
  }
  em {
    font-size: 0.93rem;
    color: var(--light);
  }
`;
export default DocsScreen;
