import React from "react";
import styled from "@emotion/styled";

const StyledButton = styled.button`
  border: 1px solid var(--light);
  padding: 3px 10px;
  color: var(--light);
  background: transparent;
  border-radius: 4px;
  /* font-size: 0.8rem; */
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: var(--monospace);
  cursor: pointer;
  &:hover {
    border: 1px solid var(--white);
    color: var(--white);
  }
`;

export const PrimaryButton = styled(StyledButton)`
  color: var(--primary-light);
  border-color: var(--primary-light);
  &:hover {
    color: var(--primary-lighter);
    border-color: var(--primary-lighter);
  }
`;

export const RedButton = styled(StyledButton)`
  color: var(--red);
  border-color: var(--red);
  &:hover {
    color: var(--white);
    background: var(--red);
  }
`;

export const BigPrimaryButton = styled(PrimaryButton)`
  font-size: 0.9rem;
  padding: 4px 10px;
`;

export const SecondaryButton = styled(StyledButton)`
  color: var(--secondary-light);
  border-color: var(--secondary-light);

  &:hover {
    border-color: var(--secondary-lighter);
    color: var(--secondary-lighter);
  }
`;

export const NeutralButton = styled(StyledButton)`
  color: var(--light);
  border-color: var(--light);

  &:hover {
    border-color: var(--white);
    color: var(--white);
  }
`;
