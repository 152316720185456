import { CodeSnippet } from "../data/interfaces";
import { useState, useRef, useEffect } from "react";
import { snippetToSnippetInput } from "../data/snippetsData";
import { isEqual } from "lodash";

export default function useSnippetForm(snippet?: CodeSnippet) {
  let snippetRef = useRef(snippetToSnippetInput(snippet));

  let [isDirty, setIsDirty] = useState(false);
  let [isValid, setIsValid] = useState(null);
  let [forceRefresh, setForceRefresh] = useState(Date.now());

  let checkIsValid = () => {
    if (!snippetRef.current) return false;
    let { title, language, collection, filename } = snippetRef.current;
    let err = "";
    if (!title) {
      err = "Title is required.";
    } else if (!language) {
      err = "Language is required.";
    } else if (!collection) {
      err = "Collection is required.";
    }

    if (!err !== isValid) {
      setIsValid(!err);
    }
  };
  let checkIsDirty = () => {
    if (!snippet || !snippetRef.current) return false;
    let isCurrentlyDirty = !isEqual(snippetToSnippetInput(snippet), snippetRef.current);
    if (isCurrentlyDirty !== isDirty) {
      setIsDirty(isCurrentlyDirty);
    }
  };

  let onPropertyChange = (id: string, updatedValue: any) => {
    if (snippetRef.current) {
      snippetRef.current[id] = updatedValue;
      checkIsDirty();
      checkIsValid();
      if (
        id === "code" ||
        id === "language" ||
        id === "title" ||
        id === "filename" ||
        id === "collection"
      ) {
        setForceRefresh(Date.now());
      }
    }
  };
  useEffect(() => {
    snippetRef.current = snippetToSnippetInput(snippet);
  }, [snippet]);

  let discardChanges = () => {
    if (snippet) {
      snippetRef.current = snippetToSnippetInput(snippet);
    }
  };

  return {
    isDirty,
    onPropertyChange,
    modifiedSnippet: snippetRef.current,
    discardChanges,
    forceRefresh,
    isValid,
  };
}
