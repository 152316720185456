import React, { useState } from "react";
import { DataSearch } from "@appbaseio/reactivesearch";
import { Link, navigate } from "@reach/router";
import styled from "@emotion/styled";

function GlobalSearch({  }: GlobalSearchProps) {
  let [searchValue, setSearchValue] = useState("");
  return (
    <StyledGlobalSearch
      dataField={["title", "createdBy"]}
      highlight={true}
      componentId="global-search"
      className="global-search"
      value={searchValue}
      debounce={300}
      onChange={(value, triggerQuery, event) => {
        setSearchValue(value);
      }}
      onValueSelected={(value) => {
        if (value.indexOf("snippet:") === 0) {
          navigate("/" + value.replace("snippet:", ""));
        } else if (value.indexOf("author:") === 0) {
          navigate(`/${value.replace("author:", "")}`);
        } else {
          navigate(`/search?query="${value}"`);
        }
        setSearchValue("");
      }}
      parseSuggestion={(suggestion) => {
        let { createdBy, collection, filename, title } = suggestion.source;
        let value = suggestion.label;
        if (suggestion.label === suggestion.source.title) {
          value = "snippet:" + [createdBy, collection, filename].join("/");
        } else if (suggestion.label === createdBy) {
          value = "author:" + createdBy;
        }

        return {
          label: suggestion.label,
          value: value,
          source: suggestion.source,
        };
      }}
      innerClass={{}}
    />
  );
}

const StyledGlobalSearch = styled(DataSearch)`
  ul li mark {
    color: var(--primary-light);
  }
`;
export default React.memo(GlobalSearch);

export interface GlobalSearchProps {
  //props
}
