import React, { useState } from "react";
const MonacoEditor = React.lazy(() => import("./MonacoEditor"));
import useDebounce from "../../hooks/core/useDebounce";
import styled from "@emotion/styled";

let defaults = {
  readOnly: false,
  language: "javascript",
  code: "// Your snipppet here...",
};

function CodeEditor(props: CodeEditorProps) {
  let mergedProps = { ...defaults, ...props };
  let [currentValue, setCurrentValue] = useState(mergedProps.code);
  useDebounce(
    () => {
      if (props.onChange) props.onChange(currentValue);
    },
    500,
    [currentValue]
  );

  let onChange = (updatedValue: string) => {
    setCurrentValue(updatedValue);
  };

  let monacoProps = {
    ...mergedProps,
    onChange,
    value: mergedProps.code,
  };
  return (
    <React.Fragment>
      <MonacoEditor {...monacoProps} />
    </React.Fragment>
  );
}

export default React.memo(CodeEditor);

export interface CodeEditorProps {
  code?: string;
  language?: string;
  readOnly?: boolean;
  onChange?: (code: string) => void;
}
