import React from "react";
import styled from "@emotion/styled";
import { CurrentUser, useCurrentUser } from "../../hooks/useCurrentUser";

export default function CurrentUserActions() {
  let { isLoggedIn, user, loading } = useCurrentUser();

  if (!isLoggedIn && !loading) return null;
  if (loading) return <span />;
  if (!user) return null;
  return (
    <StyledCurrentUser>
      <div className="username">{user.username}</div>
      <img src={user.profilePhoto} />
    </StyledCurrentUser>
  );
}

const StyledCurrentUser = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  img {
    width: 35px;
    border-radius: 50%;
    margin-left: -25px;
    opacity: 0.5;
    z-index: 0;
  }
  .username {
    text-transform: initial;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 1);
    z-index: 1;
  }
`;
