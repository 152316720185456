import { useSaveSnippet } from "./useSnippetApi";
import { navigate } from "@reach/router";
import useSnippetForm from "./useSnippetForm";
import { DEFAULT_SNIPPET } from "../data/snippetsData";

export const useNewSnippet = function() {
  let { isDirty, onPropertyChange, modifiedSnippet, isValid } = useSnippetForm(DEFAULT_SNIPPET);
  let _saveToDb = useSaveSnippet(modifiedSnippet);
  let onSave = async () => {
    // TODO: validate!
    if (!isDirty) return;

    // call graphql mutation?
    console.log("Saving Snippet", modifiedSnippet);
    let { data, error } = await _saveToDb();
    if (data && data.saveSnippet) {
      navigate("/" + data.saveSnippet.path);
    }
  };

  return {
    snippet: modifiedSnippet,
    isDirty,
    onPropertyChange,
    onSave,
    isValid,
  };
};
