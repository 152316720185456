import { useEffect } from "react";
import useLocation from "./core/useLocation";

const uaId = process.env.GOOGLE_ANALYTICS;

declare global {
  interface Window {
    ga: any;
  }
}
export default function useGoogleAnalytics(username = "") {
  let { pathname, host } = useLocation();

  useEffect(() => {
    if (uaId && host === "codesnippets.dev") {
      let options: any = {};
      if (username) {
        options.clientId = btoa(username);
      }
      window.ga("create", uaId, options);
    }
  }, [username]);

  useEffect(() => {
    // Only do it on PROD
    if (host === "codesnippets.dev") {
      window.ga("set", "page", pathname);
      window.ga("send", "pageview");
    }
  }, [pathname]);
}
