import React, { useEffect } from "react";
import Layout, { BaseScreenProps } from "./Layout";
import useCurrentUserData, { useCurrentUser } from "../hooks/useCurrentUser";
import { navigate } from "@reach/router";

export default function HomeScreen({ location }: BaseScreenProps) {
  // let { user, loading } = useCurrentUserContext();
  // console.log("TCL: HomeScreen -> loading", loading, user);

  useEffect(() => {
    navigate("/explore");
    // if (user && user.username) {
    //   navigate("/" + user.username.toLowerCase());
    // }
  });
  // if (loading) return null;
  return <span />;
  // return (
  //   <Layout title="Home" hideCommandBar={true}>
  //     <div>
  //       <h2>Languages</h2>
  //       <ul className="monospace">
  //         {languages.map((language) => (
  //           <li key={language.key}>{language.name}</li>
  //         ))}
  //       </ul>
  //     </div>
  //   </Layout>
  // );
}
