import { lighten, darken } from "polished";
import styled from "@emotion/styled";

const FormControl = styled.div`
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  label {
    font-family: var(--monospace);
    text-transform: uppercase;
    color: ${(props) => darken(0.2, props.theme.light)};
    font-size: 0.8rem;
    margin-bottom: 4px;
    &.required::after {
      content: "*";
      color: var(--primary-light);
      position: relative;
      top: -4px;
      left: 3px;
      font-size: 0.6rem;
    }
  }

  input,
  textarea {
    background: var(--black);
    padding: 8px;
    color: var(--white);
    border: 1px solid var(--light);
    border-radius: 3px;
    font-family: var(--sans-serif);
    letter-spacing: 0.2px;
    line-height: 1.2rem;
    &:focus,
    &:active {
      box-shadow: none;
      outline-color: var(--primary);
    }
  }
`;

export default FormControl;
