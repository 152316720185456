import React from "react";
import { Router } from "@reach/router";
import HomeScreen from "./HomeScreen";
import TokenScreen from "./TokenScreen";
import NewSnippetScreen from "./NewSnippet";
import SnippetDetailsScreen from "./SnippetDetailsScreen";
import ExploreScreen from "./ExploreSceen";
import Header from "../components/pageHeader/Header";
import useGoogleAnalytics from "../hooks/useGoogleAnalytics";
import { ThemeProvider } from "emotion-theming";

import "./App.css";
import { transparentize, darken } from "polished";
import styled from "@emotion/styled";
import useCurrentUserData, { CurrentUserContext } from "../hooks/useCurrentUser";
import DocsScreen from "./DocsScreen";
import useLogRocket from "../hooks/useLogRocket";
import SearchScreen from "./SearchScreen";
import { ReactiveBase } from "@appbaseio/reactivesearch";

const CREDS = "IGXZjlmAF:81edcfec-8d21-49bb-822a-010e589b15e0";
const reactivSearchTheme = {
  colors: {
    textColor: "#eee",
    backgroundColor: "#171619",
    primaryTextColor: "#fca699",
    primaryColor: "#ff6f59",
    titleColor: "#ccc",
    alertColor: "#60eec3",
    borderColor: "#aaa",
  },
};

function App({}) {
  let currentUserData = useCurrentUserData();
  useLogRocket(currentUserData.user);
  useGoogleAnalytics(currentUserData.user ? currentUserData.user.key : "");
  return (
    <ThemeProvider theme={theme}>
      <CurrentUserContext.Provider value={currentUserData}>
        <ReactiveBase
          theme={reactivSearchTheme}
          themePreset="dark"
          app="codesnippets"
          credentials={CREDS}
          className="full-height"
          key={window.location.pathname}
        >
          <StyledApp className="app">
            <Header />
            <React.Suspense fallback={<div>Loading...</div>}>
              <Router>
                <HomeScreen path="" default />
                <NewSnippetScreen path="/new-snippet" />
                <SearchScreen path="/:username" />
                <SearchScreen path="/explore/tags/:tag" />
                <SearchScreen path="/explore/languages/:language" />
                <SearchScreen path="/:username/:collection" />
                <SearchScreen path="/search" />

                <SnippetDetailsScreen path="/:username/:collectionName/:snippetName" />

                <ExploreScreen path="/explore" />
                <ExploreScreen path="/explore/tags" />
                <ExploreScreen path="/explore/languages" />

                <TokenScreen path="/token" />
                <DocsScreen path="/docs" />
                <DocsScreen path="/docs/:docsPath" />
              </Router>
            </React.Suspense>
          </StyledApp>
        </ReactiveBase>
      </CurrentUserContext.Provider>
    </ThemeProvider>
  );
}

const theme = {
  black: "#161616",
  blackBackground: transparentize(0.5, "#161616"),
  dark: "#3a4e48",
  darkBackground: transparentize(0.5, "#3a4e48"),
  white: "#eee",
  light: "#ccc",
  primary: "#ff6f59",
  secondary: "#43aa8b",
  red: "#b6afae",
};

const StyledReactiveBase = styled(ReactiveBase)`
  height: 100%;
`;
const StyledApp = styled.div`
  min-height: 100%;
  position: relative;
  /* display: flex;
  flex-direction: column; */
  font-family: var(--sans-serif);
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas: "header" "content";
  .page-header {
    grid-area: header;
  }
  .content {
    grid-area: content;
  }

  h1,
  h2,
  h3,
  h4 {
    font-family: Inconsolata, "Courier New", Courier, monospace;
    font-weight: lighter;
    margin: 5px 0;
  }

  input {
    font-family: Montserrat, sans-serif;
  }

  label {
    font-family: var(--monospace);
    margin-bottom: 4px;
    &.required::after {
      content: "*";
      color: var(--primary-light);
      position: relative;
      top: -4px;
      left: 3px;
      font-size: 0.6rem;
    }
  }
`;
export default App;
