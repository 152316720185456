import React from "react";
import useLanguages from "../../hooks/useLanguages";
import { snippetSearchResultToCodeSnippet } from "../../data/snippetsData";
import SnippetCards from "../snippets/SnippetCards";
import { ReactiveList } from "@appbaseio/reactivesearch";
import { componentIds } from "./Refiners";

export default function SearchResults(props: any) {
  return (
    <div className="results">
      <ReactiveList
        componentId="results"
        dataField="created"
        // If it has a text query sort by ranking, otherwise by created desc
        sortBy={checkForTextQuery() ? null : "desc"}
        size={10}
        className="result-list-container"
        pagination={true}
        react={{
          and: componentIds,
        }}
        showResultStats={false}
        render={({ data, error, loading }) => {
          if (loading) return <div>Loading...</div>;
          if (error) return <div>Error: {error}</div>;
          return <ValidSnippetSearchResults data={data} />;
        }}
        {...props}
      />
    </div>
  );
}
const ValidSnippetSearchResults = ({ data }) => {
  let { languages } = useLanguages();
  let snippets = data.map((item) => snippetSearchResultToCodeSnippet(item, languages));
  return <SnippetCards snippets={snippets} />;
};

const checkForTextQuery = function() {
  let qsObject = getQueryStringData();
  return qsObject && qsObject.query;
};

const getQueryStringData = function(): any {
  return location.search
    .slice(1)
    .split("&")
    .filter(Boolean)
    .reduce((data, paramStr) => {
      let [key, value] = paramStr.split("=");
      data[key] = decodeURIComponent(value) || "";
      return data;
    }, {});
};
