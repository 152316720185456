import React, { useState } from "react";
import Layout, { BaseScreenProps } from "./Layout";
import CodeEditor from "../components/code/CodeEditor";
import { DEFAULT_SNIPPET } from "../data/snippetsData";
import SnippetForm from "../components/snippetDetails/SnippetForm";
import styled from "@emotion/styled";
import { NeutralButton, SecondaryButton } from "../components/primitives/Buttons";
import { navigate } from "@reach/router";
import { useNewSnippet } from "../hooks/useNewSnippet";
import { LoadingPulse } from "../components/primitives/Loading";

export default function NewSnippetScreen({  }: BaseScreenProps) {
  let [mode, setMode] = useState(Modes.NEW);
  let { snippet, isDirty, onSave, onPropertyChange, isValid } = useNewSnippet();

  let save = async () => {
    setMode(Modes.LOADING);
    await onSave();
  };

  let renderCommands = () => {
    if (mode === Modes.LOADING) {
      return <LoadingPulse />;
    }
    return (
      <React.Fragment>
        {isDirty && isValid && <SecondaryButton onClick={save}>Save</SecondaryButton>}
        <NeutralButton onClick={() => navigate("/")}>Cancel</NeutralButton>
      </React.Fragment>
    );
  };

  return (
    <Layout commands={renderCommands()}>
      <StyledContainer>
        <CodeEditor
          language={snippet ? snippet.language : DEFAULT_SNIPPET.language.key}
          code={snippet ? snippet.code : DEFAULT_SNIPPET.code}
          onChange={(code) => onPropertyChange("code", code)}
        />
        <SnippetForm snippet={snippet} onPropertyChange={onPropertyChange} />
      </StyledContainer>
    </Layout>
  );
}
enum Modes {
  LOADING = "loading",
  NEW = "new",
}
const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  box-sizing: border-box;
  height: 100%;
  /* grid-gap: 10px; */
`;

// Title
// Filename
// Collection
// Description
// Tags
// Code
